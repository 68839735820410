
export default [
    {
        path: '/403',
        component: () => import("@/views/403"),
        meta: { show: true },
        name: "err403"
    },
    // 教师端的首页
    {
        path: '/teacherHome',
        component: () => import("@/views/Home"),
        meta: { show: true },
        name: "teacherHome"
    },
    // 课程表
    {
        path: '/timeTable',
        component: () => import("@/views/classSchedule"),
        name: 'timeTable',
        meta: { show: true },
    },
  
    // 绑定用户信息教师
    {
        path: '/bindUserInfo',
        component: () => import("@/views/bindUserInfo"),
        name: 'bindUserInfo',
    },
     // 绑定用户的隐私协议
     {
        path: '/protocal-privacy',
        component: () => import("@/views/components/protocal-privacy"),
        name: 'protocal-privacy',
    },
       // 绑定用户的用户协议
       {
        path: '/protocal-user',
        component: () => import("@/views/components/protocal-user"),
        name: 'protocal-user',
    },
    // 绑定用户信息学员
    {
        path: '/bindUserInfoStudent',
        component: () => import("@/views/bindUserInfoStudent"),
        name: 'bindUserInfoStudent',
    },

    {
        path: '/bindUserInfoStudent',
        component: () => import("@/views/bindUserInfoStudent"),
        name: 'bindUserInfoStudent',
    },

    // 微信分享测试
    {
        path: '/shareWx',
        component: () => import("@/views/shareWx"),
        name: 'shareWx'
    },

    // 课程反馈
    {
        path: '/courseFeedback',
        component: () => import("@/views/courseDetail/courseFeedback"),
        name: 'courseFeedback'
    },


    // 教师课程安排
    {
        path: '/teacherSchedule',
        component: () => import("@/views/TeachersSchedule"),
        name: 'teacherSchedule'
    },

    // 课堂点评
    {
        path: '/reviewStudents',
        name: 'reviewStudents',
        component: () => import("@/views/TeachersSchedule/reviewStudents")
    },

    // 学生请假
    {
        path: '/studentLeave',
        component: () => import("@/views/classSchedule/studentLeave"),
        name: 'studentLeave'
    },
    // 查看请假的状态
    {
        path: '/leaveStatus',
        name: 'leaveStatus',
        component: () => import("@/views/classSchedule/leaveStatus")
    },

    //请病详细流程界面
    {
        path: '/apprProcess',
        component: () => import("@/views/classSchedule/approvalProcess"),
        name: 'apprProcess'
    },



    // ---------------------------------------------------------首页的子路由
    // 课程信息
    {
        path: '/courses',
        component: () => import('@/views/childrenRouter/courses'),
        name: 'courses'
    },
    // 课程详细信息
    {
        path: '/courseDetail',
        component: () => import('@/views/childrenRouter/courses/courseDetail'),
        name: 'courseDetail'
    },
    // 学员信息 students
    {
        path: '/students',
        component: () => import('@/views/childrenRouter/students'),
        name: 'students',
    },
    // 学员详细信息 studentDetail
    {
        path: '/studentDetail:id?',
        component: () => import('@/views/childrenRouter/students/studentDetail'),
        name: 'studentDetail',
    },

    //  班级信息
    {
        path: '/classRoom',
        component: () => import('@/views/childrenRouter/classRoom'),
        name: 'classRoom',
    },

    //  班级详细信息
    {
        path: '/classRoomDetail',
        component: () => import('@/views/childrenRouter/classRoom/classRoomDetail'),
        name: 'classRoomDetail',
    },

    // 课程表
    {
        path: '/schedule',
        component: () => import('@/views/childrenRouter/schedule'),
        name: 'schedule',
    },
    // 课程表里的上课数据界面
    {
        path: '/classStatics',
        component: () => import('@/views/childrenRouter/schedule/classStatics'),
        name: 'classStatics',
    },

    // 消课
    {
        path: '/elimination/:id?',
        component: () => import("@/views/TeachersSchedule/Elimination"),
        name: 'elimination'
    },

    // 课消里面的上课出勤情况
    {
        path: '/rollCall/:id?',
        component: () => import("@/views/TeachersSchedule/RollCall"),
        name: 'rollCall',
    },

    // _______________________________________________子路由的 招生管理

    // 意向学员列表
    {
        path: '/intentionStudent',
        component: () => import("@/views/childrenRouter/recruitStudents/intentionStudent"),
        name: 'intentionStudent',
    },
    // 详情
    {
        path: '/intentionStudent/clueDetails',
        component: () => import('@/views/childrenRouter/recruitStudents/intentionStudent/clueDetails'),
        name: 'clueDetails'
    },

    // 试听学员列表
    {
        path: '/baseAudition',
        component: () => import('@/views/childrenRouter/recruitStudents/baseAudition'),
        name: 'baseAudition'
    },

    // _______________________________________________数据中心
    {
        path: '/dataCenter',
        component: () => import('@/views/childrenRouter/dataCenter'),
        name: 'dataCenter'
    },
    {
        path: '/schoolTable',
        component: () => import('@/views/childrenRouter/dataCenter/schoolTable'),
        name: 'schoolTable'
    },
    {
        path: '/studentTable',
        component: () => import('@/views/childrenRouter/dataCenter/studentTable'),
        name: 'studentTable'
    },

    // _____________________________________________通知管理
    {
        path: '/noticeManage',
        component: () => import('@/views/childrenRouter/noticeManage'),
        name: 'noticeManage'
    },
    // 添加通知消息
    {
        path: '/noticeManage/noticeAdd',
        component: () => import('@/views/childrenRouter/noticeManage/noticeAdd'),
        name: 'noticeAdd'
    },
    // 通知详细信息  noticeDetail
    {
        path: '/noticeManage/noticeDetail/:id?',
        component: () => import('@/views/childrenRouter/noticeManage/noticeDetail'),
        name: 'noticeDetail',
    },
      //  通知数量(人员)
      {
        path: '/noticeRecive:id?',
        component: () => import('@/views/childrenRouter/noticeManage/noticeRecive'),
        name: 'noticeRecive',
    },

    // _____________________________________________课堂反馈
    {
        path: '/courseCommentList',
        component: () => import('@/views/childrenRouter/courseCommentList'),
        name: 'courseCommentList'
    },
    // 课堂反馈的学员列表 courseCommentStudentList
    {
        path: '/courseCommentStudentList',
        component: () => import('@/views/childrenRouter/courseCommentList/courseCommentStudentList'),
        name: 'courseCommentStudentList'
    },
    // 课堂反馈编辑
    {
        path: '/courseCommentEdit',
        component: () => import('@/views/childrenRouter/courseCommentList/courseCommentEdit'),
        name: 'courseCommentEdit'
    },
    // 课堂反馈详情
    {
        path: '/commentDetail/:id?',
        component: () => import('@/views/childrenRouter/courseCommentList/commentDetail'),
        name: 'commentDetail'
    },

    // _______________________________________________________学员&家长端系统

    // 学员的首页
    {
        path: '/studentHome',
        component: () => import("@/views/Home/student.vue"),
        meta: { show: true },
        name: 'studentHome'
    },
    {
        path: '/studentCard',
        component: () => import('@/views/Home/studentChildren/myInfo/studentCard'),
        name: 'studentCard'
    },
    // 课堂反馈详情
    {
        path: '/studentCourseFeedback',
        component: () => import('@/views/Home/studentChildren/home/studentCourseFeedback'),
        name: 'studentCourseFeedback'
    },

    // 课程详情
    {
        path: '/studentCourseDetail',
        component: () => import('@/views/Home/studentChildren/myInfo/studentCourseDetail'),
        name: 'studentCourseDetail'
    },
    // 学员课程表
    {
        path: '/studentSchedule',
        component: () => import('@/views/childrenStudentRouter/studentSchedule'),
        name: 'studentSchedule'
    },
    // _____________________________________________学员通知管理
    {
        path: '/studentNoticeManage',
        component: () => import('@/views/childrenStudentRouter/studentNoticeManage'),
        name: 'studentNoticeManage'
    },
    // 通知详细信息  noticeDetail
    {
        path: '/studentNoticeDetail/:id?',
        component: () => import('@/views/childrenStudentRouter/studentNoticeManage/studentNoticeDetail'),
        name: 'studentNoticeDetail',
    },

    //  学员班级信息
    {
        path: '/studentClassRoom',
        component: () => import('@/views/childrenStudentRouter/studentClassRoom'),
        name: 'studentClassRoom',
    },

    //  学员班级详细信息
    {
        path: '/studentClassRoomDetail',
        component: () => import('@/views/childrenStudentRouter/studentClassRoom/studentClassRoomDetail'),
        name: 'studentClassRoomDetail',
    },

    // 学员端未读信息的展示
    {
        path: '/studentUnreadNotice',
        component: () => import('@/views/Home/studentChildren/home/unreadNotice'),
        name: 'studentUnreadNotice',
    },
    //重定向，在项目跑起来的时候，访问/，立马定向到首页
    // {
    //     path: '*',
    //     redirect: 'https://www.daydayedu.cn/api/wx/login/teacher?nextUrl=home'
    // }
]
<template>
    <div class="box">

        <!-- 加载遮罩层 -->
        <van-overlay :show="show" @click="show = false">
            <div class="wrapper" @click.stop>
                <div class="block">
                    <van-loading type="spinner" color="#1989fa" />
                    <p class="content">正在上传...</p>
                </div>
            </div>
        </van-overlay>

    </div>
</template>

<script>
export default {
    name: "loading",
    props: ['isLoading'],
    data() {
        return {
            selectedDateInfo: [{}],
            show: true,
        }
    },
    mounted() {

        // console.log(this.homeShow)
    },
    methods: {
      
    },
    watch: {
        'isLoading': {
            immediate: true,
            handler(newVal, oldValue) {
                this.show = newVal
            },
        }
    }
}
</script>

<style lang="less" scoped>
* {
    margin: 0;
    padding: 0;
    list-style: none;

}

.van-overlay {
    .wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        background-color: #292727;

    }

    .block {
        width: 220px;
        height: 220px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: center;
        justify-content: center;
        background-color: #c0bebe;
        border-radius: 12px;

        .content {
            font-size: 24px;
        }
    }
}
</style>
import Vue from 'vue'
import App from './App.vue'
// 引入amfe-flexible
import 'amfe-flexible'
//引入路由
import router from '@/router'
//引入仓库
import store from './store';
//统一接口api文件夹里面全部请求函数
import * as API from '@/api'
// 引入vantui
import Vant from 'vant';
import 'vant/lib/index.css';

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';//样式文件
//Vue.use
//vue注入ElementUI
Vue.use(ElementUI);


// 微信jssdk
import jssdk from "weixin-js-sdk"
// Vue.use(VueWechatShare)

import { Notify } from 'vant';
Vue.use(Vant);
Vue.use(Notify)


// wx分享全局组件
import shareWx from "@/views/shareWx"
Vue.component('shareWx', shareWx)

// 加载全局组件
import loading from "@/views/components/loading"
Vue.component('loading', loading)



// echarts
import * as echarts from 'echarts'

Vue.config.productionTip = false

new Vue({
    render: h => h(App),
    beforeCreate() {
        Vue.prototype.$bus = this;
        Vue.prototype.$API = API;
        Vue.prototype.$wx = jssdk;
        Vue.config.ignoredElements = ['wx-open-launch-app', 'wx-open-launch-weapp'];
        Vue.prototype.$echarts = echarts

        //需要挂载到Vue原型上
    },
    router,
    //组件实例身上会多一个属性$store属性
    store,
}).$mount('#app')

import request from '@/api/request'
/** 
 * 数据总览
 * @param {object} params 校区数组
 * @param {array} params.schoolIds 选择的校区id数组
 * @returns
 */
export function overview(params) {
    return request.post(`/api/data/overview`, params);
}


/** 
* 每日简报
* @param {object} params param
* @param {object} params.date 选择日期，不传默认今天
* @param {array} params.schoolIds 选择的校区id数组
* @returns
*/
export function daily(params) {
    return request.post(`/api/data/daily`, params);
}


/** 
* 月度数据
* @param {object} params param
* @param {number} params.month 月份
* @param {array} params.schoolIds 选择的校区id数组
* @param {number} params.year 年份
* @returns
*/
export function month(params) {
    return request.post(`/api/data/month`, params);
}


/** 
* 年度数据
* @param {object} params param
* @param {array} params.schoolIds 选择的校区id数组
* @param {number} params.year 年份
* @returns
*/
export function year(params) {
    return request.post(`/api/data/year`, params);
}

/** 
* 校区费用数据
* @param {object} params param
* @param {array} params.courseIds 
* @param {object} params.datePeriodParam 
* @param {array} params.schoolIds 
* @returns
*/
export function schoolFeeData(params) {
    return request.post(`/api/data/fee/school`, params);
}

/** 
* 学员费用数据
* @param {object} params param
* @param {array} params.classIds 
* @param {array} params.courseIds 
* @param {array} params.schoolIds 
* @param {string} params.studentName 
* @returns
*/
export function studentFeeData(params) {
    return request.post(`/api/data/student/fee`, params);
}


/** 
* 学员课时数据
* @param {object} params param
* @param {array} params.classIds 
* @param {array} params.courseIds 
* @param {array} params.schoolIds 
* @param {string} params.studentName 
* @returns
*/
export function studentTimeData(params) {
    return request.post(`/api/data/student/timetable`, params);
}

/** 
* 财务数据分析（周期）
* @param {object} params param
* @param {object} params.periodParam 
* @param {array} params.schoolIds 
* @param {number} params.type 1.按学员，2.课程，3.班级，4.教师，5.校区
* @param {number} params.year 
* @returns
*/
export function feeDetailByDates(params) {
    return request.post(`/api/data/fee/detail/dates`, params);
}

/** 
 * 教务数据分析（周期）
 * @param {object} params param
 * @param {object} params.periodParam 
 * @param {array} params.schoolIds 
 * @param {number} params.type 1.按学员，2.课程，3.班级，4.教师，5.校区
 * @param {number} params.year 
 * @returns
 */
export function educationDetailByDates(params) {
    return request.post(`/api/data/education/detail/dates`, params);
  }
// 家校互动

import request from '@/api/request'
/** 
 * 获取反馈记录
 * @param {string} organizationId 机构id，超级管理员时需要
  * @param {object} params param
 * @param {array} params.schoolIds 校区id数组（多选）
 * @param {array} params.classroomIds 教室id数组（多选）
 * @param {array} params.courseIds 课程id数组（多选）
 * @param {array} params.classIds 班级id数组（多选）
 * @param {array} params.teacherIds 老师或助教id数组（多选）
 * @param {number} params.status 状态（2:已消课，1:待消课）
 * @param {object} params.startDate 开始日期 
 * @param {object} params.endDate 结束日期 
 * @returns
 */
export function listFeedbackTimetable(organizationId, params) {
    return request.post(`/api/home/feedback/list${organizationId == null ? '' : ('?organizationId=' + organizationId)}`, params);
  }


  /** 
 * 获取待反馈记录
 * @param {string} organizationId 机构id，超级管理员时需要
  * @param {object} params param
 * @param {array} params.schoolIds 校区id数组（多选）
 * @param {array} params.classroomIds 教室id数组（多选）
 * @param {array} params.courseIds 课程id数组（多选）
 * @param {array} params.classIds 班级id数组（多选）
 * @param {array} params.teacherIds 老师或助教id数组（多选）
 * @param {number} params.status 状态（2:已消课，1:待消课）
 * @param {object} params.startDate 开始日期 
 * @param {object} params.endDate 结束日期 
 * @returns
 */
export function todoFeedbackTimetable(organizationId, params) {
    return request.post(`/api/home/feedback/todo${organizationId == null ? '' : ('?organizationId=' + organizationId)}`, params);
  }

  /** 
 * 新增课堂反馈
 * @param {object} params list
 * @param {number} params.timetableId 课表id
 * @param {array} params.list 反馈信息列表
 * @returns
 */
export function addFeedback(params) {
    return request.post(`/api/home/feedback/add`, params);
  }


  /** 
 * 编辑课堂反馈
 * @param {object} params list
 * @param {number} params.timetableId 课表id
 * @param {array} params.list 反馈信息列表
 * @returns
 */
export function updateFeedback(params) {
    return request.post(`/api/home/feedback/update`, params);
  }


  /** 
 * 课堂反馈详情
 * @param {string} timetableId timetableId
  * @returns
 */
export function feedbackInfo(timetableId) {
    return request.get(`/api/home/feedback/info/${timetableId}`);
  }

  /** 
 * 获取课堂反馈评分模板列表
 * @param {string} organizationId 机构id，超级管理员时需要
  * @returns
 */
export function organizationTemplate(organizationId) {
    return request.get(`/api/school/setting/template${organizationId == null ? '' : ('?organizationId=' + organizationId)}`);
  }

  /** 
 * 获取学员通知记录
 * @param {string} organizationId 机构id，超级管理员时需要
  * @returns
 */
export function listNotice(organizationId) {
    return request.get(`/api/home/notice/list${organizationId == null ? '' : ('?organizationId=' + organizationId)}`)
  }
  
  /** 
 * 查看学员通知详情
 * @param {string} id 通知id
  * @returns
 */
export function getNoticeInfo(id) {
    return request.get(`/api/home/notice/info/${id}`);
  }

  /** 
 * 新增学员通知
 * @param {object} params param
 * @param {object} params.param 通知内容参数
 * @param {array} params.studentIds 学员id数组
 * @param {number} params.organizationId 机构id，超级管理员时需要
 * @returns
 */
export function addNotice(params) {
    return request.post(`/api/home/notice/add`, params);
  }
  /** 
 * 反馈记录详情
 * @param {string} feedbackId feedbackId
  * @returns
 */
export function feedbackDetail(feedbackId) {
    return request.get(`/api/home/feedback/detail/${feedbackId}`);
  }


/** 
 * 删除学员通知
 * @param {string} id 通知id
  * @returns
 */
export function deleteNotice(id) {
    return request.get(`/api/home/notice/delete/${id}`);
  }

  /** 
 * 删除反馈记录
 * @param {string} feedbackId feedbackId
  * @returns
 */
export function feedbackDelete(feedbackId) {
    return request.get(`/api/home/feedback/delete/${feedbackId}`);
  }

  /** 
 * 查看学员通知阅读状态
 * @param {string} id 通知id
  * @returns
 */
export function getNoticeStatus(id) {
    return request.get(`/api/home/notice/status/${id}`);
  }

import request from '@/api/request'



// 获取课程信息
export const getCalendar = (data, openid) => {
    return request({
        url: `/api/class/course/wx/${openid}`,
        method: 'POST',
        data
    })
}

// 获取课程信息反馈
export const getCoursefeed = (data) => {
    return request({
        url: '/api/class/course/wx/status',
        method: 'POST',
        data
    })
}

// 
export const getJsapi = (url) => {
    return request({
        method: 'GET',
        url: `/wx/my/jsapi?url=${url}`
    })
}

// 
export const share = () => {
    return request({
        method: 'GET',
        url: `/wx/my/share`
    })
}

// 测试 微信分享功能
export const getshare = () => {
    return request({
        method: 'GET',
        url: `/wx/my/zan?id=1`
    })
}

// 老师查看课程信息
export function listCourseByTeacher(params) {
    return request.post(`/api/class/course/wx/teacher`, params);
}

//   上传文件
// /wx/my/download/{id}
export function download(id) {
    return request.get(`/wx/my/download?id=${id}`);
}


/** 
 * 腾讯云cos临时密钥
 * @returns
 */
export function getCredential() {
    return request.get(`/api/resource/credential`);
  }


// 家长端
import * as moveTeacher from './moveTeacher'
import * as education from './education/index'
import * as recruitStudents from './recruitStudents/index'
import * as dataCenter from './dataCenter/index'
// 家校互通
import * as homeAndSchool from './homeAndSchool/index'




// 学员家长端的接口
import *as moveStudent from './moveStudent'

export { education, recruitStudents,dataCenter,homeAndSchool,moveStudent,moveTeacher }

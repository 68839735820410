<template >
    <div>
        <!-- 我是微信分享
        <br> -->
        <!-- <button > <a href="http://vruwte.natappfree.cc/wx/my/zan?id=1">点赞</a></button> -->
        <button @click="startVoice">录音开始</button>
        <button @click="stopVoice">停止录音</button>
        <button @click="playVoiceD(Id)">播放录音</button>
        <button @click="pauseVoice">暂停播放</button>
        <button @click="uploadVoice">上传</button>
        <!-- <button @click="stopVoice">录音开始</button> -->
        <audio controls
            src="https://tiancai-1305202367.cos.ap-nanjing.myqcloud.com/dev-test/2023-07-27/c36410a06bcf49f38658d614e2514e16.mp3"></audio>





        <!-- 照片 -->
        <div>
            <van-uploader v-model="fileList" multiple :max-count="3" />
        </div>

        <div>
            <van-uploader v-model="videoList" :max-count="3" preview-size="120px" accept="video/*"
                :after-read="handlevideosc" :before-read="handlebefread" @click-preview="handleclicksc">
                <div slot="preview-cover">
                    <video style="width:120px;height:120px;object-fit:cover;" :src="videourl" v-if="videourl"></video>
                </div>
            </van-uploader>
            <div style="font-size:12px;color:#777">视频大小不能超过100M</div>
            <van-dialog style="width:100%;border-radius:0;height:200px" theme="default" v-model="showvideoplay"
                :show-cancel-button="false" :show-confirm-button="false" closeOnClickOverlay>
                <video controls preload="auto" style="width:100%;height:200px;object-fit: contain;" :src="videourl"
                    v-if="videourl"></video>
            </van-dialog>
        </div>
    </div>
</template>

<script>
export default {
    name: "shareWx",
    data() {
        return {
            Id: '1111',  // 录音的音频地址
            fileList: [
                { url: 'https://img01.yzcdn.cn/vant/leaf.jpg' },
                // Uploader 根据文件后缀来判断是否为图片文件
                // 如果图片 URL 中不包含类型信息，可以添加 isImage 标记来声明
            ],  // 照片地址

            // 视频列表
            carinfo: {
                videofile: "",
                ngEcoOpenNo: ""
            },
            videoList: [],
            videourl: "",
            showvideoplay: false
        }
    },
    mounted() {
        this.getWx()
        // console.log(wx, 111)

    },
    methods: {

        async share() {
            await this.$API.share()
        },
        // 微信分享
        async getWx() {
            try {

                let that = this
                let result = await this.$API.getJsapi(location.href.split('#')[0])
                // console.log(result, 777)
                // console.log(this.$wx.config, 121212)
                wx.config({
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: result.appId, // 必填，公众号的唯一标识
                    timestamp: result.timestamp, // 必填，生成签名的时间戳
                    nonceStr: result.nonceStr, // 必填，生成签名的随机串
                    signature: result.signature,// 必填，签名
                    jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData', 'onMenuShareAppMessage', , 'startRecord', 'stopRecord', 'playVoice', 'pauseVoice', 'uploadVoice'], // 必填，需要使用的JS接口列表
                });
                wx.ready(function () {   //需在用户可能点击分享按钮前就先调用
                    // console.log(wx,23232323)
                    console.log('ready')
                    wx.updateAppMessageShareData({
                        title: '大家好', // 分享标题
                        desc: '你值得更好的教育天天教育', // 分享描述
                        link: 'http://duqxce.natappfree.cc/shareWx', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                        imgUrl: 'https://www.baidu.com/img/flexible/logo/pc/result.png', // 分享图标
                    }, function (res) {
                        that.$API.share()
                        // console.info("success",12312341241241234)
                    });
                    wx.updateTimelineShareData({
                        title: 'test', // 分享标题
                        link: 'http://duqxce.natappfree.cc/shareWx', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                        imgUrl: 'https://www.baidu.com/img/flexible/logo/pc/result.png', // 分享图标
                    }, function (res) {
                        console.log(111)
                        that.$API.share()

                        console.info("success")
                    });
                    wx.onMenuShareAppMessage({
                        title: 'test', // 分享标题
                        desc: '你值得更好的教育天天教育', // 分享描述
                        link: 'http://duqxce.natappfree.cc/shareWx', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                        imgUrl: 'https://www.baidu.com/img/flexible/logo/pc/result.png', // 分享图标
                        success: function () {
                            that.$API.share()

                        },
                        cancel: function () {
                            // CNZZ_PUSH(["_trackEvent", "课程详情", "取消分享", '发送给朋友', 0, null]);
                        }
                    });
                });

                wx.checkJsApi({
                    jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData', 'startRecord', 'stopRecord', 'playVoice', 'pauseVoice'], // 需要检测的JS接口列表，所有JS接口列表见附录2,
                    success: function (res) {
                        // 以键值对的形式返回，可用的api值true，不可用为false
                        // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
                        console.log(res, 678)
                    }
                });
            } catch (error) {
                console.log(error)
            }
        },
        // 发请求
        getShare() {
            try {
                this.$API.getshare()
                // console.log(result)
            } catch (error) {
                // console.log(error)

            }
        },

        getShares() {
            this.getShare()
        },


        // 开始录音函数
        startVoice() {
            console.log(wx, 222)
            wx.startRecord();
        },
        // 停止录音函数
        stopVoice() {
            let that = this
            wx.stopRecord({
                success: function (res) {
                    that.Id = res.localId;
                    alert('停止', 222);
                    alert(that.Id)
                }


            })
        },

        // 开始播放录音函数
        playVoiceD(id) {
            alert(id)

            wx.playVoice({
                localId: id// 需要播放的音频的本地ID，由stopRecord接口获得
            })
            alert('录音开始播放')

        },
        // 停止播放录音函数
        pauseVoice() {
            wx.playVoice({
                localId: '' // 需要播放的音频的本地ID，由stopRecord接口获得
            })
        },

        // 上传
        uploadVoice() {
            let that = this
            alert(that.Id)
            wx.uploadVoice({
                localId: that.Id, // 需要上传的音频的本地ID，由stopRecord接口获得
                isShowProgressTips: 1, // 默认为1，显示进度提示
                success: async (res) => {
                    var serverId = res.serverId; // 返回音频的服务器端ID
                    try {
                        let result = await that.$API.download(serverId)
                        alert(result)

                    } catch (error) {

                    }
                }
            })
        },







        handlebefread(e) {
            if (e.size > 104857600) {
                this.$Message.error("选择/录制视频不能超过100M");
                return false;
            }
            return true;
        },
        handlevideosc(file) {
            this.videourl = file.content;
        },
        handleclicksc(file) {
            this.showvideoplay = true;
        },
        handleconfirpop() {
            if (this.videoList.length > 0) {
                this.carinfo.videofile = this.videoList[0].content;
            }
            //这里写提交后台代码
        },
        handleplay(item) {
            this.show = true;
            this.videoList = [];
            this.carinfo.ngEcoOpenNo = item.ngEcoOpenNo;
        }
    },
    created() {
        // console.log(this.$wx)
        // let a = this.readFile('.../MP_verify_zN3pAMEyce8YwhLu.txt')
        // console.log(a)

    },

}



</script>
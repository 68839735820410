//对于axios进行二次封装
import axios from 'axios';
//引入进度条
import nprogress from 'nprogress';
import 'nprogress/nprogress.css'
import { Toast } from 'vant';
//利用axios对象的方法，去创建一个axios实例
const requests = axios.create({
    //配置对象
    //基础路径，发请求的时候，路径当中会出现api
    // baseURL:'/api',
    //代表请求超时的时间5S
    timeout: 5000,
});
//请求拦截器
requests.interceptors.request.use((config) => {
    // if(store.state.detail.uuid_token){
    //     //给请求头添加一个字段
    //     config.headers.userTempId = store.state.detail.uuid_token
    // }
    // //需要携带token带给服务器
    // if(store.state.user.token){
    //     config.headers.token  = store.state.user.token;
    // }
    //进度条开始动
    nprogress.start();
    // 让每个请求携带自定义token 请根据实际情况自行修改
    let token = localStorage.getItem('Authorization');
    // console.log(token, 'token')
    if (token) {

        config.headers.Authorization = token;
        if ( config.url.startsWith('/api/student/bind')) {
            if (config.headers['Authorization']) {
                delete config.headers['Authorization']
            }
        } else if (config.url.startsWith('/api/student/login')) {
            if (config.headers['Authorization']) {
                delete config.headers['Authorization']
            }
        }
    }
    return config;
    // return config;
})

//响应拦截器
requests.interceptors.response.use((res) => {
    nprogress.done();

    let authorization = res.headers.authorization
    // console.log(authorization,'authorization')
    // if(res.headers.authorization=='')
    if (authorization !== undefined && authorization !== null && authorization !== '') {
        localStorage.setItem('Authorization', authorization);
    }
    // 全局捕获错误 
    /**
    * code为非20000是抛错 可结合自己业务进行修改
    */
    const res_ = res.data
    if (res_.status !== 'success') {
        
        if ( res_.data.errCode == 100006 || res_.data.errCode == 100008) {
           
            const toast = Toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: '账户未登录正在尝试自动登录',
            });

            let second = 3;
            const timer = setInterval(() => {
                second--;
                if (second) {
                    toast.message = `账户未登录正在尝试自动登录`;
                } else {
                    clearInterval(timer);
                    // 本地跑项目网址
                    window.location.replace("https://www.daydayedu.cn/api/wx/login/student?nextUrl=home");

                    // 部署到线上的
                    // window.location.replace("https://www.daydayedu.cn/api/wx/login/student?nextUrl=home");

                    // 手动清除 Toast
                    Toast.clear();
                }
            }, 1000);
        } 
         // 100012校区停用
        //  if (res_.data.errCode == 100012 ) {
        //     this.$router.replace({name:'403'})
        //     Toast(res_.data.errMsg)
        //  }  
         
           // 100012 校区停用
        if (res_.data.errCode == 100012 ) {
            window.location.replace('403');
        }  
       
        // https://dev.icc.ah.cn/wx/api/login/student?nextUrl=home  // 学员的登录地址

   
        return res.data
    } else {
        // Toast( res.data)
        return res.data
    }
},
    (error) => {
        return Promise.reject(new Error('faile'));
    })


//对外暴露
export default requests;
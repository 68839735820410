//state：存储数据的地方
const state = {
  
};
//mutations：修改state的唯一手段
const mutations = {

}
//actions：处理actions，可以书写自己的业务逻辑，也可以处理异步
const actions = {
   
}
//getters：计算属性，用于简化仓库数组，让组件获取仓库数组更加方便
const getters = {}
export default {
    state,
    mutations,
    actions,
    getters
}
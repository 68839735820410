import requestStudent from '@/api/requestStudent'

/** 
 * 绑定并登录√
 * @param {string} openid openid
  * @param {string} phone phone
  * @returns
 */
export function bindByPhone(openid, phone) {
    return requestStudent.post(`/api/student/bind?openid=${openid}&phone=${phone}`);
}
/** 
 * 解除绑定√
 * @returns
 */
export function unbind() {
    return requestStudent.get(`/api/student/unbind`);
}

/** 
* 手机端登录√
* @param {string} openid openid
 * @returns
*/
export function loginByOpenid(openid) {
    return requestStudent.post(`/api/student/login?openid=${openid}`);
}

/** 
 * 获取当前学员信息√
 * @returns
 */
export function info() {
    return requestStudent.get(`/api/student/info`);
}

/** 
* 获取学生月课表√
* @param {object} params param
* @param {number} params.year 年份
* @param {number} params.month 月份
* @returns
*/
export function listMonthTimetable(params) {
    return requestStudent.post(`/api/student/timetable/month`, params);
}

/** 
* 获取学生班级列表√
* @returns
*/
export function listClassInfo() {
    return requestStudent.get(`/api/student/class/list`);
}

/** 
* 查看学生班级详情√
* @param {string} id id
* @returns
*/
export function classInfo(id) {
    return requestStudent.get(`/api/student/class/info/${id}`);
}

/** 
* 查看下一节课√
* @returns
*/
export function nextTimetable() {
    return requestStudent.get(`/api/student/timetable/next`);
}
/** 
 * 获取已绑定学员列表√
 * @returns
 */
export function list() {
    return requestStudent.get(`/api/student/list`);
}


/** 
 * 查看课堂反馈详情√
 * @param {string} id id
  * @returns
 */
export function feedbackInfo(id) {
    return requestStudent.get(`/api/student/feedback/info/${id}`);
}
/** 
 * 查看课程列表√
 * @returns
 */
export function courseList() {
    return requestStudent.get(`/api/student/course/list`);
}

/** 
 * 查看课时卡√
 * @param {string} 课程id id
  * @returns
 */
export function courseInfo(id) {
    return requestStudent.get(`/api/student/course/info/${id}`);
}

/** 
 * 查看课程的课消记录√
 * @param {string} 课程 id
  * @returns
 */
export function logListByCourseId(id) {
    return requestStudent.get(`/api/student/course/log/${id}`);
}

/** 
* 查看课程的课堂反馈√
* @param {string} 课程id id
* @returns
*/
export function feedbackListByCourseId(id) {
    return requestStudent.get(`/api/student/course/feedback/${id}`);
}

/** 
* 查看所有通知列表√
* @returns
*/
export function listNotice() {
    return requestStudent.get(`/api/student/notice/list/all`);
}

/** 
* 查看通知详情√
* @param {string} id id
* @returns
*/
export function getNoticeDetail(id) {
    return requestStudent.get(`/api/student/notice/detail/${id}`);
}

/** 
* 查看课堂反馈列表√
* @returns
*/
export function feedbackList() {
    return requestStudent.get(`/api/student/feedback/list`);
}

/** 
* 切换默认登录学员√
* @param {string} id id
* @param {string} openid openid
* @returns
*/
export function changeStudent(id, openid) {
    return requestStudent.get(`/api/student/change?id=${id}&openid=${openid}`);
}

/** 
* 查看未读通知列表√
* @returns
*/
export function unreadNotice() {
    return requestStudent.get(`/api/student/notice/list/unread`);
}

/** 
* 阅读通知√
* @param {string} id id
* @returns
*/
export function readNotice(id) {
    return requestStudent.get(`/api/student/notice/read/${id}`);
}

/** 
 * 查看班级的课堂反馈√
 * @param {string} 班级id id
  * @returns
 */
export function feedbackListByClassId(id) {
    return requestStudent.get(`/api/student/class/feedback/${id}`);
  }

  /** 
 * 查看班级的课消记录√
 * @param {string} 班级id id
  * @returns
 */
export function logListByClassId(id) {
    return requestStudent.get(`/api/student/class/log/${id}`);
  }

  /** 
 * 查看班级课时卡√
 * @param {string} 班级id id
  * @returns
 */
export function classCourseInfo(id) {
    return requestStudent.get(`/api/student/class/course/info/${id}`);
  }
import request from '@/api/request'
/** 
 * 意向学员列表√
 * @param {string} organizationId 机构id，超级管理员时需要
  * @param {object} params param
 * @param {object} params.dateParam 录入时间
 * @param {number} params.level 意向等级
 * @param {string} params.name 意向学员姓名
 * @param {array} params.courseIds 意向课程id（多选）
 * @param {array} params.statuses 跟进状态（多选），6：已转化，5：待跟进，4：跟进中，3：已邀约，2：已试听，1：已到访
 * @param {array} params.schoolIds 意向校区id（多选）
 * @param {array} params.staffIds 录入员工id（多选）
 * @returns
 */
export function listFollowUpStudent(organizationId, params) {
    return request.post(`/api/enrolment/student/list${organizationId == null ? '' : ('?organizationId=' + organizationId)}`, params);
  }


  /** 
 * 试听列表√
 * @param {string} organizationId 机构id，超级管理员时需要
  * @param {object} params param
 * @param {object} params.dateParam 录入时间
 * @param {number} params.level 意向等级
 * @param {array} params.courseIds 试听课程id（多选）
 * @param {array} params.statuses 到课状态（多选），5：未到，4：迟到到课，3：到课，2：已请假，1：待上课
 * @param {number} params.studentStatus 是否转化，1：未转化，2：已转化
 * @param {array} params.schoolIds 试听校区id（多选）
 * @param {array} params.staffIds 任课教师id（多选）
 * @returns
 */
export function listAuditionTimetable(organizationId, params) {
    return request.post(`/api/enrolment/audition/list?organizationId=${organizationId}`, params);
  }

  /** 
 * 意向学员详情√
 * @param {string} id 意向学员id
  * @returns
 */
export function getStudentInfo(id) {
    return request.get(`/api/enrolment/student/info/${id}`);
  }

/** 
 * 意向学员沟通记录√
 * @param {string} studentId studentId
  * @returns
 */
export function studentContactLogList(studentId) {
    return request.get(`/api/enrolment/contact/student/${studentId}`);
  }


  /** 
 * 沟通记录详情√
 * @param {string} id id
  * @returns
 */
export function getContactLogInfo(id) {
    return request.get(`/api/enrolment/contact/info/${id}`);
  }

  /** 
 * 获取简要班级信息列表√
 * @param {string} schoolId 学校id
  * @returns
 */
export function listClassInfo(schoolId) {
    return request.get(`/api/education/class/list/info?schoolId=${schoolId}`);
  }
<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style scoped>
*{
    margin: 0;
    padding: 0;
    /* background: rgb(200, 198, 198); */
}

</style>

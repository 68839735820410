import request from '@/api/request'

/** 
 * 教师登录并绑定√
 * @param {string} openid openid
  * @param {object} params 登录参数
 * @param {string} params.account 账户（手机号）
 * @param {string} params.password 密码
 * @returns
 */
export function loginAndBind(openid, params) {
    return request.post(`/api/system/login/bind?openid=${openid}`, params);
}
/** 
* 手机端登录√
* @param {string} openid openid
* @returns
*/
export function loginByOpenid(openid) {
    return request.post(`/api/system/login/openid?openid=${openid}`);
}

/** 
* 获取教师上课数据√
* @param {object} params param
* @param {number} params.schoolId 学校id
* @param {number} params.teacherId 教师id
* @param {object} params.datePeriodParam 开始结束日期
* @returns
*/
export function getTeacherData(params) {
    return request.post(`/api/education/teacher/data`, params);
}


/** 
 * 获取教师首页数据√
 * @returns
 */
export function getTeacherIndexData() {
    return request.get(`/api/education/teacher/index/data`);
}

/** 
* 获取用户角色列表
* @returns
*/
export function getRoles() {
    return request.get(`/api/system/staff/role`);
}

/** 
 * 获取当前登录用户信息*
 * @returns
 */
export function info() {
    return request.get(`/api/system/staff/info`);
}


/** 
 * 解除微信绑定√
 * @returns
 */
export function unbind() {
    return request.get(`/api/system/unbind`);
  }
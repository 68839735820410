import request from '@/api/request'
// —————————————————————教师端———————————————————————
/** 
 * 教师登录并绑定√
 * @param {string} openid openid
  * @param {object} params 登录参数
 * @param {string} params.account 账户（手机号）
 * @param {string} params.password 密码
 * @returns
 */
export function loginAndBind(openid, params) {
    return request.post(`/api/system/login/bind?openid=${openid}`, params);
  }
  /** 
 * 手机端登录√
 * @param {string} openid openid
  * @returns
 */
export function loginByOpenid(openid) {
    return request.post(`/api/system/login/openid?openid=${openid}`);
  }




/** 
 * 获取课程列表√
 * @param {string} organizationId 机构id，超级管理员时需要
  * @param {object} params 筛选参数
 * @param {array} params.schoolIds 授课校区id数组
 * @param {string} params.name 课程名称模糊搜索
 * @param {array} params.propertyMaps 自定义属性id与值列表
 * @returns
 */
export function listCourse(organizationId, params) {
    return request.post(`/api/education/course/list${organizationId == null ? '' : ('?organizationId=' + organizationId)}`, params);
  }
/**
* 获取校区列表√
* @param {string} organizationId 机构id，超级管理员时需要
* @returns
*/
export function schoolList(organizationId) {
    return request.get(`/api/school/area/list${organizationId == null ? '' : ('?organizationId=' + organizationId)}`)
}

/** 
* 获取学员列表√
* @param {string} organizationId 机构id，超级管理员时需要
* @param {object} params 筛选信息
* @param {array} params.schoolIds 校区id，可多选
* @param {string} params.name 学生姓名
* @param {string} params.phone 手机号后4位
* @param {array} params.contactPersonTypes 联系人类型，可多选
* @param {number} params.gender 性别
* @param {object} params.birthday 生日
* @param {boolean} params.subStatus 是否家长端是否绑定
* @param {array} params.teacherIds 跟进老师id，可多选
* @param {array} params.classIds 班级id，可多选
* @param {array} params.courseIds 课程id，可多选
* @param {array} params.propertyMaps 自定义属性
* @param {number} params.pn 当前页数
* @param {number} params.size 每页数量
* @returns
*/
export function listStudent(organizationId, params) {
    return request.post(`/api/education/student/list${organizationId == null ? '' : ('?organizationId=' + organizationId)}`, params);
}

/**
 * 获取自定义属性列表√
 * @param {string} belong 1：学员，2：课程，3：班级
  * @param {string} organizationId 机构id，超级管理员时需要
  * @returns
 */
export function organizationProperties(belong, organizationId) {
    return request.get(`/api/school/setting/property?belong=${belong}&${organizationId == null ? '' : ('?organizationId=' + organizationId)}`)
}

/** 
* 获取班级列表√
* @param {string} organizationId 机构id，超级管理员时需要
* @param {object} params param
* @param {array} params.schoolIds 校区id数组（多选）
* @param {array} params.classroomIds 教室id数组（多选）
* @param {array} params.courseIds 课程id数组（多选）
* @param {array} params.dayNums 上课星期数组（多选）
* @param {number} params.period 上课时段，1：上午，2：下午
* @param {number} params.status 班级状态（2:已结班，1:招生中）
* @param {object} params.startDate 开班日期区间
* @param {object} params.endDate 结班日期区间
* @param {array} params.teacherIds 老师或助教id数组（多选）
* @param {array} params.propertyMaps 自定义属性id与值列表
* @param {number} params.pn 当前页数
* @param {number} params.size 每页数量
* @returns
*/
export function listClass(organizationId, params) {
    return request.post(`/api/education/class/list${organizationId == null ? '' : ('?organizationId=' + organizationId)}`, params);
}


/** 
 * 获取简要班级信息列表√
 * @param {string} schoolId 学校id
  * @returns
 */
export function listClassInfo(schoolId) {
    return request.get(`/api/education/class/list/info?schoolId=${schoolId}`);
}

/** 
* 获取简要学员列表√
* @param {string} organizationId 机构id，超级管理员时需要
 * @returns
*/
export function listStudentName(organizationId) {
    return request.get(`/api/education/student/list/name?${organizationId == null ? '' : ('?organizationId=' + organizationId)}`);
}



/** 
* 获取简要课程信息列表√
* @param {string} schoolId 学校id
* @returns
*/
export function listCourseInfo(schoolId) {
    return request.get(`/api/education/course/list/info?schoolId=${schoolId}`);
}


/** 
* 获取简要老师信息列表√
* @param {string} schoolId 学校id
* @returns
*/
export function listTeacherName(schoolId) {
    return request.get(`/api/education/teacher/list/name?schoolId=${schoolId}`);
}



//   _____________________________________________________________课表接口______________________________
/** 
 * 获取全部月课表√
 * @param {object} params param
 * @param {number} params.year 年份
 * @param {number} params.month 月份
 * @returns
 */
export function listMonthTimetable(params) {
    return request.post(`/api/education/timetable/month/all`, params);
}


/** 
 * 获取老师月课表√
 * @param {object} params param
 * @param {number} params.year 年份
 * @param {number} params.month 月份
 * @returns
 */
export function listMonthTimetableByTeacher(params) {
    return request.post(`/api/education/timetable/month/teacher`, params);
}



/** 
* 获取课表学员记上课状态列表√
* @param {string} id 课表id
 * @returns
*/
export function listTimetableStudentStatus(id) {
    return request.get(`/api/education/timetable/log/info/${id}`);
}


  /** 
 * 消课√
 * @param {object} params param
 * @param {number} params.id 课表id
 * @param {array} params.statusSaveParams 学生到课状态数组
 * @returns
 */
  export function updateTimetableStudentStatus(params) {
    return request.post(`/api/education/timetable/log/update`, params);
  }


  /** 
 * 查看班级详细信息√
 * @param {string} id 班级id
  * @returns
 */
export function getClassDetail(id) {
    return request.get(`/api/education/class/detail/${id}`);
  }


  /** 
 * 撤销消课√
 * @param {string} id 课表记录状态id
  * @returns
 */
export function cancelTimetableStudentStatus(id) {
    return request.get(`/api/education/timetable/log/cancel/${id}`);
  }


  /** 
 * 查看学员信息√
 * @param {string} id 学员id
  * @returns
 */
export function getStudentInfo(id) {
    return request.get(`/api/education/student/info/${id}`);
  }

  /** 
 * 获取教师上课数据√
 * @param {object} params param
 * @param {number} params.schoolId 学校id
 * @param {number} params.teacherId 教师id
 * @param {object} params.datePeriodParam 开始结束日期
 * @returns
 */
export function getTeacherData(params) {
    return request.post(`/api/education/teacher/data`, params);
  }


  /** 
 * 查看课程信息√
 * @param {string} id 课程id
  * @returns
 */
export function getCourseInfo(id) {
    return request.get(`/api/education/course/info/${id}`);
  }

 
//对于axios进行二次封装
import axios from 'axios';
//在当前目录中引入store
import store from '@/store';
import router from '@/router';
//引入进度条
import nprogress from 'nprogress';
import 'nprogress/nprogress.css'
import { Toast } from 'vant';
//利用axios对象的方法，去创建一个axios实例
const requests = axios.create({
    //配置对象
    //基础路径，发请求的时候，路径当中会出现api
    // baseURL:'/api',
    //代表请求超时的时间5S
    timeout: 5000,
});
//请求拦截器
requests.interceptors.request.use((config) => {
    // if(store.state.detail.uuid_token){
    //     //给请求头添加一个字段
    //     config.headers.userTempId = store.state.detail.uuid_token
    // }
    // //需要携带token带给服务器
    // if(store.state.user.token){
    //     config.headers.token  = store.state.user.token;
    // }
    //进度条开始动
    nprogress.start();
    // 让每个请求携带自定义token 请根据实际情况自行修改
    let token = localStorage.getItem('Authorization');
    // console.log(token, 'token')
    if (token) {

        config.headers.Authorization = token;
        if (config.url.startsWith('/api/system/login/openid')) {
            if (config.headers['Authorization']) {
                delete config.headers['Authorization']
            }
        } else if (config.url.startsWith('/api/system/login/bind')) {
            if (config.headers['Authorization']) {
                delete config.headers['Authorization']
            }
        }
    }
    return config;
    // return config;
},error=>{
    console.log('err:',error)
    // if(error.code === '')
})

//响应拦截器
requests.interceptors.response.use((res) => {
    nprogress.done();

    let authorization = res.headers.authorization
    // console.log(authorization,'authorization')
    // if(res.headers.authorization=='')
    if (authorization !== undefined && authorization !== null && authorization !== '') {
        localStorage.setItem('Authorization', authorization);
    }
    // 全局捕获错误 
    /**
    * code为非20000是抛错 可结合自己业务进行修改
    */
    const res_ = res.data
    if (res_.status !== 'success') {
        // 用户未登录            100006 代表token过期
        if (res_.data.errCode == 100003 || res_.data.errCode == 100006 ) {
           
            const toast = Toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: '账户未登录正在尝试自动登录',
            });

            let second = 3;
            const timer = setInterval(() => {
                second--;
                if (second) {
                    toast.message = `账户未登录正在尝试自动登录`;
                } else {
                    clearInterval(timer);
                    // 部署到线上的
                    window.location.replace("https://www.daydayedu.cn/api/wx/login/teacher?nextUrl=home");

                    // 本地
                    // window.location.replace("https://dev.icc.ah.cn/wx/api/login/teacher");

                    // 手动清除 Toast
                    Toast.clear();
                }
            }, 1000);
        }  
        // 100005校区停用
        if (res_.data.errCode == 100005 ) {
            window.location.replace('403');
        }  
        // token过期
        // if ( res_.data.errCode == 100006) {
           
        //     const toast = Toast.loading({
        //         duration: 3, // 持续展示 toast
        //         forbidClick: true,
        //         message: '账户未登录正在尝试自动登录',
        //     });

        //     let second = 3;
        //     const timer = setInterval(() => {
        //         second--;
        //         if (second) {
        //             toast.message = `账户未登录正在尝试自动登录`;
        //         } else {
        //             clearInterval(timer);
        //             // 倒计时结束进入教师绑定界面
        //             // router.replace({ path: 'https://dev.icc.ah.cn/wx/api/login/teacher' })
        //             window.location.replace("https://dev.icc.ah.cn/wx/api/login/teacher?nextUrl=home");

        //             // 手动清除 Toast
        //             Toast.clear();
        //         }
        //     }, 1000);
        // }  
        // https://dev.icc.ah.cn/wx/api/login/student?nextUrl=home  // 学员的登录地址

        // 50008:非法的token; 50012:其他客户端登录了;  50014:Token 过期了;
        // if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
        //   MessageBox.confirm(
        //     '你已被登出，可以取消继续留在该页面，或者重新登录',
        //     '确定登出',
        //     {
        //       confirmButtonText: '重新登录',
        //       cancelButtonText: '取消',
        //       type: 'warning'
        //     }
        //   ).then(() => {
        //     store.dispatch('FedLogOut').then(() => {
        //       location.reload() // 为了重新实例化vue-router对象 避免bug
        //     })
        //   })
        // }
        return res.data
    } else {
        // Toast( res.data)
        return res.data
    }
},
    (error) => {
        return Promise.reject(new Error('faile'));
    })


//对外暴露
export default requests;